import { Row, Column, Box, H2 } from '@vp/swan'
import { useTranslation } from 'react-i18next'

interface CategoriesTitleProps {
  tenant: string
  locale: string
}
export const CategoriesTitle = (props: CategoriesTitleProps) => {
  const { tenant, locale } = props
  const lng = locale?.toLowerCase().replace('-', '_')
  const { t } = useTranslation(tenant)

  return (
    <Row pb={4}>
      <Column span={12}>
        <Box display={{ sm: 'none', xs: 'none', md: 'block' }}>
          <H2
            fontSkin='title-section'
            display='flex'
          >
            {t(`${tenant}:articles.grid_home_page.title`, { lng })}
          </H2>
        </Box>
      </Column>
    </Row>
  )
}
