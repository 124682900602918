export const getHelpCenterUrl = (paths: Record<string, string>, currentUrl: string): string => {
  const currUrl = currentUrl || `${window.location.origin}${window.location.pathname}`
  const newPathsArray = currUrl.split('/')
  let url = '/'
  let index = 0
  const urlLimit = newPathsArray[1].length === 2 ? 3 : 2
  newPathsArray.forEach(path => {
    if (index < urlLimit && path.trim() !== '') {
      url = `${url}${path}/`
      index++
    }
  })

  // search page
  if (currUrl.indexOf(`/${paths.search}`) !== -1) {
    return `${currUrl.substring(0, currUrl.indexOf(`/${paths.search}`))}`
  } else {
    return `${url}`
  }
}

export const extractPathFromUrl = (url?: string) => {
  if (!url) return ''
  let newUrl = url.substr(0, url.length)
  if (newUrl.substr(-1) === '/') {
    newUrl = url.substr(0, url.length - 1)
  }
  const idStr = newUrl.substring(newUrl.lastIndexOf('/') + 1)
  return idStr.match(/(\d*)/)?.[0]
}
export const formatUrl = (url?: string) => {
  const newUrl = url?.toLowerCase()
  if (newUrl?.[0] === '/') {
    return newUrl.substr(1, newUrl.length)
  }
  return newUrl || ''
}

export const cleanPath = (url?: string) => {
  if (!url) return ''
  let newUrl = url?.substr(0, url.length)
  if (newUrl?.substr(-1) !== '/') {
    newUrl = newUrl + '/'
  }
  return newUrl
}

export const getLastPathParam = (path: string | undefined): string => {
  if (typeof path !== 'string' || !path.trim()) {
    return ''
  }
  const cleanPath = path.match(/^[^?#]+/)?.[0] ?? ''
  const pathParts = cleanPath.split('/').filter(Boolean)

  return pathParts[pathParts.length - 1] || ''
}
