import { BoundedContent, Box, Column, getRootClassNames, GridContainer, H2, Link, ResponsiveImage, Row, tokens, useBrowserClasses } from '@vp/swan'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import LeftArrowIcon from '../../assets/images/left_arrow.svg'
import { Category } from '../../types/category.types'
import { getHelpCenterUrl } from '../../utils/url.utils'
import { SectionsByCategoryList } from './SectionsByCategoryList'
import { useEffect } from 'react'
import { initCA } from '@vp/chat-anywhere'
import { isChatEnabled } from '../../utils/chat.utils'

interface Props {
  tenant: string
  locale: string
  category: Category
}
export const SectionsByCategory = (props: Props) => {
  const { category, tenant, locale } = props
  const lng = locale?.toLowerCase().replace('-', '_')
  const { t } = useTranslation(tenant)
  const bodyClassName = getRootClassNames()
  const htmlClassName = useBrowserClasses()
  const handleGetBack = () => {
    const paths = t(`${tenant}:paths.search`, { lng, returnObjects: true })
    if (typeof window !== 'undefined' && window.location && window.location.href) {
      window.location.href = getHelpCenterUrl(paths as Record<string, string>, window.location.pathname)
    }
  }

  useEffect(() => {
    if (isChatEnabled(locale)) initCA()
  }, [locale])

  return (
    <>
      <Helmet
        bodyAttributes={{ class: bodyClassName }}
        htmlAttributes={{ class: htmlClassName }}
        defer
      >
        <title>{category.categoryName} - {t(`${tenant}:page.seo.title`, { lng })}</title>
        <html lang={locale} />
      </Helmet>
      <BoundedContent style={{ maxWidth: '1440px' }}>
        <GridContainer>
          <Row>
            <Column
              span={4}
              spanXs={12}
              spanSm={12}
              spanMd={4}
              spanLg={4}
              style={{ padding: `${tokens.SwanSemSpaceBetweenSections} ${tokens.SwanSemSpaceGutterStandard} 0px ${tokens.SwanSemSpaceGutterStandard}`, gap: tokens.SwanSemSpaceGutterStandard }}
            >
              <Row ml={3}>
                <Link href='#' fontWeight='bold' skin='unstyled' onClick={handleGetBack}>
                  <Box mr='3' style={{ display: 'inline-flex' }}>
                    <ResponsiveImage role='presentation' src={LeftArrowIcon} alt='' height='10px' />
                  </Box>
                  {t(`${tenant}:articles.links.back_to_help_center`, { lng })}
                </Link>
              </Row>
            </Column>
            <Column
              span={8}
              spanXs={12}
              spanSm={12}
              spanMd={8}
              spanLg={8}
              style={{ padding: `${tokens.SwanSemSpaceBetweenSections} ${tokens.SwanSemSpaceGutterStandard} 0px ${tokens.SwanSemSpaceGutterStandard}`, gap: tokens.SwanSemSpaceGutterStandard }}
            >
              <Row>
                <Column
                  span={12}
                  spanXs={12}
                  spanSm={12}
                  spanMd={12}
                  spanLg={12}
                >
                  <H2 fontSkin='title-section'>{category.categoryName}</H2>
                </Column>
              </Row>
              <Row>
                <Column
                  span={12}
                  spanXs={12}
                  spanSm={12}
                  spanMd={12}
                  spanLg={12}
                >
                  <SectionsByCategoryList categorySections={category.categorySections} />
                </Column>
              </Row>
              <Row
                display={{ lg: 'none', md: 'none', sm: 'none' }}
                marginTop={9}
              />
              <Row
                display={{ xs: 'none', sm: 'block' }}
                marginTop={10}
              />
            </Column>
          </Row>
        </GridContainer>
      </BoundedContent>
    </>
  )
}
