import { GridContainer, Column, Row, Box, Card, Link, tokens, BoundedContent, getRootClassNames, useBrowserClasses, H2 } from '@vp/swan'
import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'
import styles, { stylesheet } from './PopularArticles.scss'
import { useStyles } from '@vp/ubik-context'
import { Helmet } from 'react-helmet'
import { Article } from '../../types/article.types'

interface Props {
  tenant: string
  locale: string
  promotedArticles: Article[]
}
export const PopularArticles = (props: Props) => {
  const { tenant, locale, promotedArticles } = props
  const lng = locale?.toLowerCase().replace('-', '_')
  const { t } = useTranslation(tenant)
  useStyles(stylesheet)
  let indexArticle = 0
  const bodyClassName = getRootClassNames()
  const htmlClassName = useBrowserClasses()
  return (
    <>
      <Helmet
        bodyAttributes={{ class: bodyClassName }}
        htmlAttributes={{ class: htmlClassName }}
        defer
      >
        <title>{t(`${tenant}:page.seo.title`, { lng })}</title>
        <html lang={locale} />
      </Helmet>
      <BoundedContent style={{ maxWidth: '1440px' }}>
        <GridContainer>
          <Row>
            <Column span={12} spanXs={12} spanSm={12} spanMd={12} spanLg={12}>
              <Row>
                <Column span={12}>
                  <Box className={styles.popularArticlesContainer}>
                    <Row style={{ padding: `${tokens.SwanSemSpaceBetweenSections} 0px 0px 0px` }}>
                      <Column
                        span={12}
                        spanXs={12}
                      >
                        <Row>
                          <Column
                            span={12}
                            spanXs={12}
                          >
                            <H2
                              fontSkin='title-section'
                            >
                              {t(`${tenant}:articles.popular_articles.title`, { lng })}
                            </H2>
                          </Column>
                        </Row>
                        <Row
                          display={{ lg: 'none', md: 'none', sm: 'none' }}
                            // marginTop={"6"}
                          style={{ paddingBottom: tokens.SwanSemSpaceBetweenSubsections }}
                        />
                        <Row
                          display={{ xs: 'none', sm: 'block' }}
                            // marginTop={7}
                          style={{ paddingBottom: tokens.SwanSemSpaceBetweenSubsections }}
                        />
                        <Row as='ul'>
                          {promotedArticles.map(function (article) {
                            indexArticle++
                            let addArticleBottomMargin = true
                            let addArticleXsBottomMargin = true
                            let articleurl = article.articleUrl
                            if (indexArticle === promotedArticles.length) {
                              addArticleBottomMargin = false
                              addArticleXsBottomMargin = false
                            } else if (indexArticle === (promotedArticles.length - 1)) {
                              if (indexArticle % 2 === 1) {
                                addArticleBottomMargin = false
                              }
                            }
                            if (typeof window !== 'undefined') {
                              const domPurify = DOMPurify(window)
                              articleurl = domPurify.sanitize(article.articleUrl)
                            }

                            return (
                              (
                                <Column
                                  as='li'
                                  className='popular-article'
                                  key={article.articleTitle}
                                  span={4}
                                  spanSm={6}
                                  spanXs={12}
                                  flexColumn
                                >
                                  <Card bordered className={styles.paBox}>
                                    <Link href={articleurl} id='articlelink'>
                                      {article.articleTitle}
                                      <span className={styles.paBoxArrow} />
                                    </Link>
                                  </Card>
                                  {addArticleBottomMargin
                                    ? (
                                      <>
                                        <Box display={{ lg: 'none', md: 'none' }} mt='4' />
                                        <Box display={{ sm: 'none', xs: 'none', md: 'block' }} mt='6' />
                                      </>
                                      )
                                    : null}
                                  {addArticleXsBottomMargin
                                    ? (
                                      <>
                                        <Box display={{ lg: 'none', md: 'none' }} mt='4' />
                                      </>
                                      )
                                    : null}
                                </Column>
                              )
                            )
                          })}
                        </Row>
                      </Column>
                    </Row>
                  </Box>
                </Column>
              </Row>
            </Column>
          </Row>
        </GridContainer>
      </BoundedContent>
    </>
  )
}
