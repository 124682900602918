import { Box, Column, Dropdown, DropdownOption, H2, Row } from '@vp/swan'
import { useStyles } from '@vp/ubik-context'
import DOMPurify from 'dompurify'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Section } from '../../types/section.types'
import styles, { stylesheet } from './Categories.scss'
interface JumpToSectionProps {
  tenant: string
  locale: string
  sectionsIndexed: Section[]
}
export const JumpToSection = (props: JumpToSectionProps) => {
  const { tenant, locale, sectionsIndexed } = props
  const lng = locale?.toLowerCase().replace('-', '_')
  const { t } = useTranslation(tenant)
  useStyles(stylesheet)

  const handleChangeSection = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== '-') {
      const section = sectionsIndexed[Number(e.target.value)]
      if (typeof window !== 'undefined') {
        const domPurify = DOMPurify(window)
        const currentUrl = window.location.origin
        if (window.location && window.location.href) {
          window.location.href = `${currentUrl}${domPurify.sanitize(section.sectionUrl)}`
        }
      }
    }
  }
  return (
    <Row style={{ width: '110%' }}>
      <Column span={12}>
        <Box display={{ lg: 'none', md: 'none' }}>
          <Row
            className={styles.jumpToSectionContainer}
            display={{ lg: 'none', md: 'none' }}
            paddingTop='6'
          />
          <Row
            className={styles.jumpToSectionContainer} paddingX='4'
          >

            <H2
              fontSkin='title-section'
              display='flex'
            >
              {t(`${tenant}:articles.grid_home_page.title`, { lng })}
            </H2>
          </Row>
          <Row
            className={styles.jumpToSectionContainer}
            display={{ lg: 'none', md: 'none' }}
            paddingTop='6'
          />
          <Row
            className={styles.jumpToSectionContainer}
            paddingLeft='5'
            paddingRight='5'
          >
            <Dropdown onChange={handleChangeSection} className={styles.sectionsDropdown}>
              <DropdownOption value='-'>{t(`${tenant}:articles.jump_to_a_section.title`, '', { lng })}</DropdownOption>
              {sectionsIndexed.map((section, index) => {
                return (
                  <DropdownOption value={index} key={section.sectionName}>{section.sectionName}</DropdownOption>
                )
              })}
            </Dropdown>
          </Row>
          <Row
            className={styles.jumpToSectionContainer}
            display={{ lg: 'none', md: 'none' }}
            paddingTop='7'
          />
        </Box>
      </Column>
    </Row>
  )
}
