import { Box, Row, Column, tokens, Link, Typography, ListItem, Divider, H3, Ul } from '@vp/swan'
import { Section } from '../../types/section.types'

interface Props {
  categorySections: Section[]
}
export const SectionsByCategoryList = (props: Props) => {
  const { categorySections } = props
  return (
    <Box>
      <Row>
        <Column
          span={12}
          spanXs={12}
        >
          <Row>
            {categorySections.map((section, indexSection) => {
              let indexArticle = 0
              return (
                (
                  <Column
                    key={`${section.sectionUrl}-${indexSection}`}
                    span={6}
                    spanXs={12}
                    flexColumn
                    display='inline-block' style={{ paddingTop: tokens.SwanSemSpace9 }}
                  >
                    <Link href={section.sectionUrl} fontWeight='bold' skin='unstyled'>
                      <H3 fontSkin='title-subsection'>{section.sectionName}</H3>
                    </Link>
                    <Box display={{ lg: 'none', md: 'none', sm: 'none' }} mb='6' />
                    <Box display={{ xs: 'none', sm: 'block' }} mb={7} />
                    <Ul skin='minimal'>
                      {section.sectionArticles.map(function (article: { articleUrl: any; articleTitle: string }) {
                        indexArticle++
                        return (
                          (
                            <ListItem key={`section-article-${indexArticle}`}>
                              <Box paddingX={0} paddingY={4}>
                                <Link href={article.articleUrl} skin='unstyled'>
                                  <Typography>{article.articleTitle}</Typography>
                                </Link>
                                <Divider marginBottom='3' marginTop='3' />
                              </Box>
                            </ListItem>
                          )
                        )
                      })}
                    </Ul>
                  </Column>
                )
              )
            })}
          </Row>
        </Column>
      </Row>
    </Box>
  )
}
