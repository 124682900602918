import { Box, Row, Column, ListItem, Link, Divider } from '@vp/swan'
import { FormattedArticle } from '../../types/article.types'

interface Props {
  sectionArticles: FormattedArticle[]
}
export const SectionArticlesList = (props: Props) => {
  const { sectionArticles } = props
  return (
    <Box>
      <Row>
        <Column span={12} spanXs={12}>
          <Row>
            <Column as='ul' span={12} spanXs={12} flexColumn display='inline-block'>
              <>
                {sectionArticles.map((article, index) => {
                  return (
                    (
                      <ListItem key={`grid-section-article-${index}`}>
                        <Box paddingY={4} paddingX={0}>
                          <Link href={article.articleUrl} skin='unstyled'>
                            {article.articleTitle}
                          </Link>
                          <Divider marginBottom='3' marginTop='3' />
                        </Box>
                      </ListItem>
                    )
                  )
                })}
              </>
            </Column>
          </Row>
        </Column>
      </Row>
    </Box>
  )
}
