import { Box, Column, Divider, FlexBox, H3, Icon, Li, Link, Row, tokens, Ul } from '@vp/swan'
import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import { Category } from '../../types/category.types'

interface Props {
  tenant: string
  locale: string
  categoriesHomePage: Category[]
}
export const CategoriesGrid = (props: Props) => {
  const { tenant, locale, categoriesHomePage } = props
  const lng = locale?.toLowerCase().replace('-', '_')
  const { t } = useTranslation(tenant)
  let indexCategory = 0
  return (
    <Row>
      <Column span={12} pt={6}>
        <Box>
          <Row>
            <Column
              span={12}
              spanXs={12}
            >
              <Row>
                {categoriesHomePage?.map(function (category, i) {
                  indexCategory++
                  let indexSection = 0
                  let addCategoryBottomMargin = true
                  let addCategoryXsBottomMargin = true
                  let categoryUrl = category.helpCenterUrl
                  if (indexCategory === categoriesHomePage.length) {
                    addCategoryBottomMargin = false
                    addCategoryXsBottomMargin = false
                  } else if (indexCategory === (categoriesHomePage.length - 1)) {
                    if (indexCategory % 2 === 1) {
                      addCategoryBottomMargin = false
                    }
                  }
                  if (typeof window !== 'undefined') {
                    const domPurify = DOMPurify(window)
                    categoryUrl = domPurify.sanitize(category.helpCenterUrl)
                  }
                  return (
                    (
                      <Column
                        key={`${category.helpCenterUrl}-${i}`}
                        span={6}
                        spanXs={12}
                        flexColumn
                        display='inline-block'
                        className='article-category'
                      >
                        <Link href={categoryUrl} skin='unstyled'>
                          <H3 fontSkin='title-subsection'>{category.categoryName} </H3>
                        </Link>
                        <Box display={{ lg: 'none', md: 'none', sm: 'none' }} pb={4} />
                        <Box display={{ xs: 'none', sm: 'block' }} pb={4} />
                        <Box>
                          <Ul skin='minimal'>
                            {category.categorySections.map((section: { sectionUrl: string; sectionName: string }, j: any) => {
                              indexSection++
                              let addSectionBottomMargin = true
                              let addSectionXsBottomMargin = true
                              let sectionUrl = section.sectionUrl
                              if (indexSection === (category.categorySections.length)) {
                                addSectionBottomMargin = false
                                addSectionXsBottomMargin = false
                              }
                              if (indexSection < 6) {
                                if (typeof window !== 'undefined') {
                                  const domPurify = DOMPurify(window)
                                  sectionUrl = domPurify.sanitize(section.sectionUrl)
                                }
                                return (
                                  (
                                    <Li className='article-section' key={`${section.sectionName}-${j}`}>
                                      <Box paddingX={0} paddingY={4} style={{ gap: tokens.SwanSemSpaceGutterStandard }}>
                                        <Link href={sectionUrl} skin='unstyled'>
                                          {section.sectionName}
                                        </Link>
                                        <Divider mt={3} />
                                      </Box>
                                      {addSectionBottomMargin
                                        ? (
                                          <>
                                            <Box display={{ lg: 'none', md: 'none', sm: 'none' }} style={{ paddingBottom: tokens.SwanSemSpace4 }} />
                                            <Box display={{ xs: 'none', sm: 'block', md: 'block' }} style={{ paddingBottom: tokens.SwanSemSpace4 }} />
                                          </>
                                          )
                                        : null}
                                      {addSectionXsBottomMargin
                                        ? (
                                          <>
                                            <Box display={{ lg: 'none', md: 'none', sm: 'none' }} style={{ paddingBottom: tokens.SwanSemSpace4 }} />
                                          </>
                                          )
                                        : null}
                                    </Li>
                                  )
                                )
                              }
                              return null
                            })}
                          </Ul>
                          <Box display={{ lg: 'none', md: 'none', sm: 'none' }} style={{ paddingBottom: tokens.SwanSemSpace4 }} />
                          <Box display={{ xs: 'none', sm: 'block' }} style={{ paddingBottom: tokens.SwanSemSpace4 }} />
                          <Box>
                            <Link href={categoryUrl} fontWeight='bold' skin='unstyled'>
                              <FlexBox justifyContent='center' aria-label={`${t(`${tenant}:articles.grid_home_page.see_all`, { lng })} ${category.categoryName}`}>
                                {t(`${tenant}:articles.grid_home_page.see_all`, { lng })}
                                <Box display={{ xs: 'none' }}>
                                  <span> {category.categoryName} </span>
                                </Box>
                                <Box ml='3' style={{ display: 'inline-flex' }}>
                                  <Icon iconType='arrowRight' />
                                </Box>
                              </FlexBox>
                            </Link>
                          </Box>
                          {addCategoryBottomMargin
                            ? (
                              <>
                                <Box display={{ lg: 'none', md: 'none', sm: 'none' }} mt={9} />
                                <Box display={{ xs: 'none', sm: 'block' }} mt={10} />
                              </>
                              )
                            : null}
                          {addCategoryXsBottomMargin
                            ? (
                              <>
                                <Box display={{ lg: 'none', md: 'none', sm: 'none' }} mt={9} />
                              </>
                              )
                            : null}
                        </Box>
                      </Column>
                    )
                  )
                })}
              </Row>
            </Column>
          </Row>
        </Box>
      </Column>
    </Row>
  )
}
