import { BoundedContent, GridContainer, Row, tokens } from '@vp/swan'
import { CategoriesGrid } from './CategoriesGrid'
import { CategoriesTitle } from './CategoriesTitle'
import { JumpToSection } from './JumpToSection'
import { Section } from '../../types/section.types'
import { Category } from '../../types/category.types'

interface Props {
  tenant: string
  locale: string
  categoriesHomePage: Category[]
  sectionsIndexed: Section[]
}
export const BrowseCategories = (props: Props) => {
  const { tenant, locale, categoriesHomePage, sectionsIndexed } = props

  return (
    <BoundedContent style={{ maxWidth: '1440px' }}>
      <GridContainer>
        <Row style={{ padding: `${tokens.SwanSemSpaceBetweenSections} ${tokens.SwanSemSpaceGutterStandard}` }}>
          <CategoriesTitle locale={locale} tenant={tenant} />
          <JumpToSection locale={locale} tenant={tenant} sectionsIndexed={sectionsIndexed} />
          <CategoriesGrid locale={locale} tenant={tenant} categoriesHomePage={categoriesHomePage} />
        </Row>
      </GridContainer>
    </BoundedContent>
  )
}
