const CA_ALLOWED_DESKTOP_LOCALES = 'en-us,en-gb,en-ie,en-ca,en-au,en-nz,en-sg,da-dk,de-at,de-ch,de-de,es-es,es-us,fi-fi,fr-be,fr-ca,fr-ch,fr-fr,it-ch,it-it,nb-no,sv-se,nl-nl,nl-be'
const CA_ALLOWED_MOBILE_LOCALES = 'es-us,en-us,en-ca,fr-ca,en-au,en-nz,en-sg,es-es,it-it,en-ie,en-gb,fr-fr,fr-be,de-de,de-ch,it-ch,fr-ch,de-at,sv-se,fi-fi,da-dk,nb-no,nl-nl,nl-be'

const isDesktop = () => {
  const userAgent = navigator?.userAgent?.toLowerCase() ?? ''
  const isMobile = /iPhone|Android/i.test(navigator.userAgent)

  const isTablet =
          /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
          )

  return !isMobile && !isTablet
}

export const isChatEnabled = (locale: string) => {
  if (locale) {
    // Allowed locales for CA
    const allowedLocalesDesktop = CA_ALLOWED_DESKTOP_LOCALES.split(',')
    const allowedLocalesMobile = CA_ALLOWED_MOBILE_LOCALES.split(',')

    if (isDesktop() && allowedLocalesDesktop) return allowedLocalesDesktop.includes(locale?.toLowerCase()) // CA Allow locales(Desktop)
    return !isDesktop() && allowedLocalesMobile?.includes(locale?.toLowerCase()) // CA Allow locales(Mobile)
  } else {
    return false
  }
}
