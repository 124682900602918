import { useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { BrowseCategories } from './components/categories/BrowseCategories'
import { PopularArticles } from './components/popular-articles/PopularArticles'
import { SectionArticles } from './components/section-articles/SectionArticles'
import { SectionsByCategory } from './components/sections/SectionsByCategory'
import { Article } from './types/article.types'
import { Category } from './types/category.types'
import { Section } from './types/section.types'
import LocalizationProvider from './common/contexts/LocalizationProvider'

export interface Props {
  component: string
  articles?: Article[]
  categories?: Category[]
  sections?: Section[]
  section?: Section
  category?: Category
  localization?: Record<string, any>
}

export const Fragment = (props: Props) => {
  const { tenant = 'vistaprint', locale } = useUserContext()
  const {
    component,
    articles,
    categories,
    sections,
    section,
    category,
    localization
  } = props

  useSwanStyleKeys(['core'])

  function getComponent () {
    switch (component) {
      case 'PopularArticles':
        return <PopularArticles tenant={tenant} locale={locale} promotedArticles={articles || []} />
      case 'BrowseCategories':
        return (
          <BrowseCategories
            tenant={tenant} locale={locale} categoriesHomePage={categories || []}
            sectionsIndexed={sections || []}
          />
        )
      case 'Sections':
        return category ? <SectionsByCategory tenant={tenant} locale={locale} category={category} /> : null
      case 'SectionsArticles':
        return section ? <SectionArticles tenant={tenant} locale={locale} section={section} /> : null
      default:
        return null
    }
  }

  return (
    <LocalizationProvider locale={locale} localizationData={localization!}>
      {getComponent()}
    </LocalizationProvider>
  )
}
